<template>
  <!-- HORIZONTAL LAYOUT -->
  <vx-card title="Edit salesman Items">
    <div class="vx-row mb-6" style="width: 50%">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
        >Back</vs-button
      >
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Territory</span>
          </div>
          <div class="vx-col sm:w-5/6 w-full">
            <vs-input class="w-full" v-model="this.territory_name" disabled />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Personal</span>
          </div>
          <div class="vx-col sm:w-5/6 w-full">
            <vs-input
              class="w-full"
              v-model="this.sales_personal_name"
              disabled
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Line Items</span>
          </div>
          <div class="vx-col sm:w-5/6 w-full">
            <vs-button size="small" class="mr-3 mb-2" @click="browseItem()"
              >Browse</vs-button
            >
          </div>
        </div>
        <br />
        <hr />
        <br />
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table :data="cartProducts" stripe style="width: 100%">
              <template slot="thead">
                <vs-th>SKU</vs-th>
                <vs-th>Name</vs-th>
                <vs-th>Min Order</vs-th>
                <vs-th>Max Order</vs-th>
                <vs-th>Action</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :key="index" v-for="(dt, index) in data">
                  <vs-td style="align-item-center">{{ dt.skuCode }}</vs-td>
                  <vs-td style="align-item-center"
                    >{{ dt.Name }} ({{ dt.Unit }})</vs-td
                  >
                  <vs-td style="align-item-center">
                    <vs-input
                      type="number"
                      v-model.number="dt.min_order"
                      :min="1"
                    />
                  </vs-td>
                  <vs-td style="align-item-center">
                    <vs-input
                      type="number"
                      v-model.number="dt.max_order"
                      :min="1"
                    />
                  </vs-td>
                  <vs-td style="align-item-center">
                    <feather-icon
                      title="Delete"
                      icon="TrashIcon"
                      svgClasses="w-5 h-5 text-danger stroke-current"
                      class="ml-2"
                      @click="deleteItemLine(index)"
                    />
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-6/12 w-full" v-if="update">
            <vs-button class="mr-3 mb-2" v-on:click="SubmitForm()"
              >Save</vs-button
            >
            <!-- <vs-button v-if="quantityChecked != true" class="mr-3 mb-2" v-on:click="CheckQty()">CheckQty</vs-button> -->
            <vs-button color="danger" class="mr-3 mb-2" @click="CancelForm()"
              >Cancel</vs-button
            >
          </div>
        </div>
      </div>
      <div
        v-if="this.optionItemAvailableShow"
        class="vx-col sm:w-1/2 w-full mb-base"
      >
        <div class="vx-row mb-6">
          <h3>Item Unit</h3>
        </div>
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="items"
          :max-items="length"
          :total="total"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.start }} - {{ this.end }} of {{ this.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th sort-key="sku">SKU</vs-th>
            <vs-th sort-key="name">Name</vs-th>
            <vs-th sort-key="action">Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in data.records"
            >
              <vs-td :data="data.records[indextr].item.sku_code">{{
                data.records[indextr].item.sku_code
              }}</vs-td>
              <vs-td :data="data.records[indextr].item.name"
                >{{ data.records[indextr].item.name }}({{
                  data.records[indextr].unit.Name
                }})
              </vs-td>
              <vs-td style="align-item-center">
                <feather-icon
                  title="Add"
                  icon="PlusIcon"
                  svgClasses="w-5 h-5 text-success stroke-current"
                  class="ml-2"
                  @click="addItemLine(tr)"
                />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="totalPage"
          v-model="setPage"
        />
      </div>
    </div>
  </vx-card>
</template>

<script>
export default {
  components: {},
  mounted() {
    // console.log("mounted - form : ", this.territoryCode, this.territoryId, this.selectedReservation, this.optionTerritory);
    this.update = true;
    this.getTerritory();
    this.territory_id = this.$route.params.territory_id;
    this.company_id = this.$route.params.company_id;
    this.sales_personal_id = this.$route.params.sales_personal_id;
    this.getProductItems();
    // console.log("hhhhhh", this.company_id);
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  watch: {
    search: function () {
      if (this.optionItemAvailableShow) {
        this.getOptionItemAvailable();
      }
    },
    itemsQuantity: function () {
      this.quantityChecked = false;
    },
  },
  data: () => ({
    update: false,
    quantityChecked: false,
    external: false,

    optionItemAvailable: [{}],

    optionCustomerShow: false,
    optionItemAvailableShow: false,

    limits: [10, 25, 50, 100, "All"],
    limitShow: 10,
    drawData: 0,
    isActive: 1,
    recordsTotal: 0,
    maxPage: 2,
    dataEnd: 0,

    items: [],
    optionTerritory: [],
    selectedTerritory: {},
    start: 1,
    end: 0,
    length: 10,
    page: 1,
    search: "",
    order: "id",
    sort: "desc",
    total: 0,
    totalPage: 0,
    totalSearch: 0,
    name: "",
    code: "",
    CompanyID: 0,
    ProductItem: [],
    ID: 0,
    cartProducts: [],
    itemUnitID: [],
    territory_name: "",
    territory_id: 0,
    sales_personal_name: "",
    sales_personal_id: 0,
  }),
  methods: {
    handleBack() {
      this.$router.push({
        name: "salesman-items",
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    containsKey(obj, key) {
      return Object.keys(obj).includes(key);
    },
    CancelForm() {
      this.update = true;
      this.quantityChecked = false;
      this.external = false;

      this.selectTerritory = {};
      this.cartProducts = [];
      this.itemUnitID = [];
      this.optionItemAvailable = [{}];
      this.optionItemAvailableShow = false;

      this.$emit("selectReservation", {});
    },
    browseItem() {
      this.search = "";
      this.optionItemAvailableShow = true;
      this.optionCustomerShow = false;
      this.getOptionItemAvailable();
      this.scrollToTop();
    },
    addItemLine(item) {
      // console.log("ini itemLine>>>",);
      this.quantityChecked = false;
      let check = this.itemUnitID.filter((val) => {
        return item.id == val;
      });
      console.log("yyyyyyyy", item, check);

      if (check.length == 0) {
        this.cartProducts.push({
          itemId: item.id,
          skuCode: item.item.sku_code,
          Name: item.item.name,
          Unit: item.unit.Name,
        });
        this.itemUnitID.push(item.id);
        this.disableTerritory = true;
      } else {
        this.$vs.notify({
          title: "Warning",
          text: "You're not allowed to add or remove external Reservation item line ",
          color: "warning",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    deleteItemLine(index) {
      this.quantityChecked = false;
      if (this.external == false) {
        this.cartProducts.splice(index, 1);
        this.itemUnitID.splice(index, 1);
      } else {
        this.$vs.notify({
          title: "Warning",
          text: "You're not allowed to add or remove external Reservation item line",
          color: "warning",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleChangelength(val) {
      this.length = val == "All" ? this.total : val;
      this.page = 1;
      this.getOptionItemAvailable();
    },
    getOptionItemAvailable(page = 1) {
      this.isActive = page;
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/salesman-items/table", {
          params: {
            territory_id: this.territory_id,
            length: this.length,
            page: this.page,
            search: this.search,
            order: this.order,
            sort: this.sort,
          },
        })
        .then((resp) => {
          console.log(">>>>>>>>", resp);
          if (resp.code == 200) {
            let company_id = this.$store.state.user.currentUser.company_id;

            this.optionItemAvailable = resp.data.ItemsAvailable;
            this.total = resp.data.total_record;
            this.totalPage = resp.data.total_page;
            this.totalSearch = resp.data.total_record_search;
            this.length = resp.data.total_record_per_page;
            this.items = resp.data;
            this.CompanyID = company_id;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Item Available",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    SubmitForm() {
      if (this.cartProducts.length == 0) {
        this.$vs.notify({
          title: "Error",
          text: "Please select Item Line first",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return true;
      }

      // this.$vs.loading();
      this.$http
        .put("/api/sfa/v1/salesman-items/update", {
          territory_id: this.territory_id,
          sales_personal_id: this.sales_personal_id,
          company_id: this.$route.query.company_id,
          item_unit: this.cartProducts,
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Reservation has been added",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            // this.CancelForm();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          // this.$vs.loading.close();
        });
    },
    getTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/route-plan/territory", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = resp.data.records;
              if (this.optionTerritory.length > 0) {
                this.selectedTerritory = this.optionTerritory[0];
              } else {
                this.optionTerritory = [];
                this.selectedTerritory = {};
              }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    customLableTerritory({ code, name }) {
      return `${code} ${name}`;
    },
    setStartEnd() {
      let valStart = this.length * this.page - this.length + 1;
      if (valStart > this.total) {
        valStart = 1;
      }
      if (this.total == 0) {
        valStart = 0;
      }
      let valEnd = this.length * this.page;
      if (valEnd > this.total) {
        valEnd = this.total;
      }
      if (this.totalSearch < this.total) {
        valEnd = this.totalSearch;
      }

      this.start = valStart;
      this.end = valEnd;
    },
    handleSearch(searching) {
      this.search = searching;
      this.page = 1;
      this.getOptionItemAvailable(this.isActive);
    },
    handleChangePage(page) {
      this.page = page;
      this.getOptionItemAvailable(this.isActive);
    },
    handleSort(key, active) {
      this.order = key;
      this.sort = active;
      this.getOptionItemAvailable(this.isActive);
    },
    getProductItems() {
      this.$http
        .get("/api/sfa/v1/salesman-items/show", {
          params: {
            company_id: this.$route.query.company_id,
            territory_id: this.$route.query.territory_id,
            sales_personal_id: this.$route.query.sales_personal_id,
          },
        })
        .then((resp) => {
          console.log("mmmmmmm", resp);
          if (resp.code == 200) {
            this.items = resp.data;
            this.territory_name = resp.data.territory.name;
            this.territory_id = resp.data.territory.id;
            this.sales_personal_name = resp.data.personal_name.name;
            this.sales_personal_id = resp.data.personal_name.id;

            for (let index = 0; index < resp.data.Data_Item.length; index++) {
              this.itemUnitID.push(resp.data.Data_Item[index].id);
              this.cartProducts.push({
                itemId: resp.data.Data_Item[index].id,
                skuCode: resp.data.Data_Item[index].item.sku_code,
                Name: resp.data.Data_Item[index].item.name,
                Unit: resp.data.Data_Item[index].unit.Name,
                min_order: resp.data.records[index].MinOrder,
                max_order: resp.data.records[index].MaxOrder,
              });
            }
          }
        });
    },
  },
};
</script>
